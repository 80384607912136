import React, { useState } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useHistory } from "react-router-dom";

import pdf from "../assets/freeminers.pdf";

const MenuLink = ({
  link,
  Icon,
  subLinks,
  title,
  spacing = false,
  onClose,
}) => {
  const [isExpand, setIsExpand] = useState(false);

  const history = useHistory();

  const handleExpand = () => setIsExpand(!isExpand);

  const handlePush = (link) => {
    history.push(link);
    onClose();
  };

  return (
    <div>
      <>
        {link ? (
          <div
            className="item-container spacing"
            onClick={() => handlePush(link)}
          >
            <div className="item link">
              <Icon className="icon" />
              <p>{title}</p>
            </div>
          </div>
        ) : (
          <div className="item-container spacing" onClick={handleExpand}>
            <div className="item link">
              <Icon className="icon" />
              <p>{title}</p>
            </div>
            {subLinks ? (
              isExpand ? (
                <FiChevronDown />
              ) : (
                <FiChevronRight />
              )
            ) : null}
          </div>
        )}
        {subLinks
          ? isExpand && (
              <div className="spacing">
                {subLinks.map(({ title, link, download }) => {
                  return download ? (
                    <a
                      href={pdf}
                      target="_blank"
                      rel="noreferrer"
                      className="subLink"
                    >
                      <HiOutlineArrowNarrowRight className="icon" />
                      {title}
                    </a>
                  ) : (
                    <div
                      onClick={() => handlePush(link)}
                      className="subLink"
                      key={title}
                    >
                      <HiOutlineArrowNarrowRight className="icon" />
                      <p>{title}</p>
                    </div>
                  );
                })}
              </div>
            )
          : null}
      </>
    </div>
  );
};

export default MenuLink;

import { FiHome, FiEdit } from "react-icons/fi";
import { BsLink45Deg, BsWallet,BsFillEaselFill } from "react-icons/bs";
import { AiOutlineTeam } from "react-icons/ai";
import { GrStakeholder } from "react-icons/gr";
import { SiCreativecommons } from "react-icons/si";
import { FaCashRegister } from "react-icons/fa";
import { BiSupport, BiTransfer } from "react-icons/bi";
import { IoDocumentTextOutline } from "react-icons/io5";

const links = [
  {
    title: "Home",
    Icon: FiHome,
    goto: "/",
    spacing: true,
    subLinks: [
      {
        title: "Dashboard",
        link: "/",
      },
      {
        title: "Profile",
        link: "/profile",
      },
      {
        title: "Bank Details",
        link: "/bank-details",
      },
      {
        title: "Change Password",
        link: "/change-password",
      },
      {
        title: "PAN Details",
        link: "/pan-details",
      },
      {
        title: "Notice Board",
        link: "/notice-board",
      },
    ],
  },

  {
    title: "Team",
    Icon: AiOutlineTeam,
    spacing: true,

    goto: "/direct-referrals",
    subLinks: [
      {
        title: "Direct Referrals",
        link: "/direct-referrals",
      },
      {
        title: "Tree Chart",
        link: "/tree-chart",
      },
    ],
  },
  {
    title: "Wallet",
    Icon: BsWallet,
    goto: "/stacking",
    spacing: true,
    subLinks: [
      { title: "Staking Wallet Report", link: "/stacking-wallet-report" },
      { title: "Earning Wallet Report", link: "/earning-report" },
      { title: "Offers Wallet Report", link: "/offers-report" },
      { title: "Transfer Wallet Report", link: "/transferWallet-report"},
    ],
  },
  {
    title: "Withdraw",
    Icon: FiEdit,
    link: "/withdraw",
  },
  {
    title: "Withdraw Report",
    Icon: FiEdit,
    link: "/Withdraw-report",
  },
  {
    title: "Staking Report",
    Icon: FiEdit,
    link: "/topupRequest",
  },
  // {
  //   title: "Plan",
  //   Icon: GrPlan,
  //   spacing: true,

  //   goto: "",
  //   subLinks: [
  //     {
  //       title: "Download PDF",
  //       link: "/download-pdf",
  //     },
  //   ],
  // },
  {
    title: "Free Coin Address",
    Icon: SiCreativecommons,
    link: "/coin-address",
  },
  {
    title: "FMDC Coin Update",
    Icon: SiCreativecommons,
    link: "/fmdc-coin-update",
  },
  // {
  //   title: "Plan",
  //   Icon: GrPlan,
  //   spacing: true,

  //   goto: "",
  //   subLinks: [
  //     {
  //       title: "Download PDF",
  //       download: "https://drive.google.com/file/d/1k5WK369B0zarxFy2LOGBGpQWe7eRGq0_/view?usp=sharing"
  //     },
  //   ],
  // },
  {
    title: "Staking",
    Icon: GrStakeholder,
    link: "/stacking",
    spacing: true,
  },
  // {
  //   title: "Stacking Report",
  //   Icon: IoDocumentTextOutline,
  //   link: "/stacking-report",
  //   spacing: true,
  // },
  {
    title: "Referral Link",
    Icon: BsLink45Deg,
    spacing: true,

    link: "/referral-link",
  },
  {title: "Transfer Wallet", Icon: BiTransfer, link:"/transfer-wallet"},
  { title: "Plan", Icon: BsFillEaselFill, link: "/plan" },

  { title: "Registration", Icon: FaCashRegister, link: "/register-user" },
  {
    title: "Support",
    Icon: BiSupport,
    link: "/support",
  },
];

export default links;

import React from "react";

import "./UserInfo.css";

const UserInfo = ({ user }) => {
  return (
    <div className="nameContainer">
      <h3>Welcome, {user?.name}</h3>
      <p>USER ID: {user?.refId}</p>
    </div>
  );
};

export default UserInfo;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changePageName } from "../../store/actions/page.actions";
import { constant } from "../../api/ApiConstant";
import { selectUser } from "../../store/reducers/auth.reducers";
import axios from "../../api/axios";
import BackButton from "../../components/BackButton";

const InvestmentHistory = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));

  const [investments, setInvestments] = useState();
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const getInvestments = async () => {
      try {
        const response = await axios.get(
          `${constant.wallet.stackingInvestment}/${user.id}`
        );
        setInvestments(response.data.data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    getInvestments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(changePageName("Stacking Investment"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) {
    <p style={{ color: "black" }}>Loading...</p>;
  }

  if (investments?.length === 0) {
    return (
      <p style={{ textAlign: "center", color: "white" }}>Nothing to Show</p>
    );
  }

  return (
    <>
      <BackButton />
      <table>
        <thead>
          <tr>
            <th>Serial No</th>
            <th>TopUp Date</th>
            <th>Coin (FC)</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {investments?.map(({ transactionAt, fcAmount, type }, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{new Date(transactionAt).toDateString()}</td>
              <td>{fcAmount}</td>
              <td>{type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default InvestmentHistory;

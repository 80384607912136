export const constant = {
  auth: {
    login: "/login",
    forgotPass: "/auth/sendMailUserIdPassword",
  },
  user: {
    getUser: "/user/getById",
    updateUser: "/user/update",
  },
  support: {
    sendSupport: "user/supportRecord/create",
  },
  team: {
    directReferrals: "/directReferalDetails",
    tree: "/userCharts",
  },
  notice: "/user/notices",
  bank: {
    fetch: "/user/bankDetails",
    update: "/user/bankDetails/update",
  },
  password: "/auth/changePassword",
  wallet: {
    stacking: "/user/topUpRequest/create",
    // stackingTopup : "/user/demo1",
    stackingTopup : "/user/internalSelfTopup",
    stackingReport: "/user/getAcceptedTopupRequests",
    stackingAccept: "/user/acceptTopupRequest",
    stackingInvestment: "/user/stackingInvestment",
    walletReport: "/user/walletReport",
    offerWalletReport: "/user/offerWalletTransactionData",
    earningWalletReport: "/user/earningsTransactionData",
    stackingWalletReport: "/user/stackingTransactionData",
    // stackingWallet: "/user/getWalletData/",
  },
  withdraw: {
    AcceptRequest: "/user/getWithdrawRequests",
  },
  dashboard: {
    dailyPrice: "/user/coinTodayPrice",
    stackingInvestment: "/user/stackingInvestment",
    ROS: "/user/getUserStatistic",
    pairMatching: "user/PairMatching",
    totalStacking: "/user/getUserPreviousBalance",
    wallet: "/user/getWalletData/for",
  },
  refUser: "/user/getByRefId",
  registration: {
    create: "/user/save",
    placementDetails: "/user/getPlacementDetails",
  },
  withdrawRequest: "/withdrawRequest/create",
  internalTransferWithdraw : "/user/transferWithdraw",
  transferWithdraw: "/user/withdrawRequest/create",
  internalTransfer: "/user/internalTransfer",
  checkUserName: "/getUserName",
  conversion: {
    coinToInr: "coinToInr",
    coinToUsd: "coinToUsd",
    usdToCoin: "/usdToCoin"
  },
  otp: {
    send: "/user/generateOtpFor",
    verify: "/user/authenticateOtpFor",
  },
  TransferWalletReport : "/user/demo9",
  previousBalance : "/user/demo4",
  stackingDate : "/user/getUserStackingDate"
};

import CoinAddress from "../pages/CoinAddress";
import BankAddress from "../pages/Home/BankAddress";
import ChangePassword from "../pages/Home/ChangePassword";
import DashboardContent from "../pages/Home/DashboardContent";
import NoticeBoard from "../pages/Home/NoticeBoard";
import PanDetails from "../pages/Home/PanDetails";
import Profile from "../pages/Home/Profile";
import Plan from "../pages/Plan";
import SendReferralLink from "../pages/SendReferralLink";
import StackingReport from "../pages/StackingReport";
import Support from "../pages/Support";
import DirectReferrals from "../pages/Team/DirectReferrals";
import TopupRequest from "../pages/Team/TopupRequest";
import AddFunds from "../pages/Wallet/AddFunds";
// import BonusWalletReport from "../pages/Wallet/BonusWalletReport";
import EarningsWalletReport from "../pages/Wallet/EarningsWalletReport";
import InvestmentHistory from "../pages/Wallet/InvestmentHistory";
import OffersWalletReport from "../pages/Wallet/OffersWalletReport";
import StackingWalletReport from "../pages/Wallet/StackingWalletReport";
import WalletReport from "../pages/Wallet/WalletReport";
import Withdraw from "../pages/Withdraw";
import WithdrawReport from "../pages/WithdrawReport";
import TransferWallet from "../pages/TransferWallet";
import TransferWalletReport from "../pages/Wallet/TransferWalletReport";
import FmdcCoinUpdate from "../pages/FmdcCoin";

const routes = [
  { path: "/", component: DashboardContent },
  { path: "/profile", component: Profile },
  { path: "/bank-details", component: BankAddress },
  { path: "/pan-details", component: PanDetails },
  { path: "/change-password", component: ChangePassword },
  { path: "/notice-board", component: NoticeBoard },
  { path: "/direct-referrals", component: DirectReferrals },
  { path: "/topupRequest",component:TopupRequest},
  { path: "/referral-link", component: SendReferralLink },
  { path: "/stacking", component: AddFunds },
  { path: "/stacking-report", component: StackingReport },
  { path: "/wallet-report", component: WalletReport },
  { path: "/earning-report", component: EarningsWalletReport },
  { path: "/stacking-wallet-report", component: StackingWalletReport },
  { path: "/offers-report", component: OffersWalletReport },
  { path: "/transferWallet-report", component: TransferWalletReport},
  { path: "/withdraw", component: Withdraw },
  { path: "/Withdraw-report", component: WithdrawReport },
  { path: "/stacking-investment", component: InvestmentHistory },
  { path: "/coin-address", component: CoinAddress },
  { path: "/fmdc-coin-update", component: FmdcCoinUpdate },
  { path: "/support", component: Support },
  { path: "/plan", component: Plan },
  { path: "/transfer-wallet", component: TransferWallet },

];

export default routes;

import axios from "axios";

const instance = axios.create({
  //baseURL:"https://us-central1-universaltraderscore-27616.cloudfunctions.net/app/free-coin",
  baseURL:"https://universaltraders-9dhh.onrender.com/free-coin/",
  // baseURL: "",
  // baseURL: "http://localhost:3000/free-coin",
});

export default instance;

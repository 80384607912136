import React from "react";
import { useHistory } from "react-router";

const BackButton = () => {
  const history = useHistory();

  return (
    <button onClick={() => history.goBack()} type="button" className="back_btn">
      Back
    </button>
  );
};

export default BackButton;

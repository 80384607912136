import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changePageName } from "../../store/actions/page.actions";
import "./NoticeBoard.css";
import axios from "../../api/axios";
import { constant } from "../../api/ApiConstant";
import { selectUser } from "../../store/reducers/auth.reducers";
import BackButton from "../../components/BackButton";

const NoticeBoard = () => {
  const [notices, setNotices] = useState([]);

  const user = useSelector((state) => selectUser(state));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePageName("Notice Board"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchNotices = async () => {
      const response = await axios.get(`${constant.notice}/${user.id}`);

      const values = [];
      for (let el in response.data.data) values.push(...response.data.data[el]);

      setNotices(values);
    };
    fetchNotices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackButton />
      <div className="card-container">
        <div className="noticeBoard-details">
          {notices.length === 0 ? (
            <p style={{ textAlign: "center" }}>Noting to show</p>
          ) : (
            notices?.map(({ message, id, createdAt }, index) => (
              <div key={index} className="message-container">
                <p className="message">{message}</p>
                <p className="time">{new Date(createdAt).toDateString()}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default NoticeBoard;

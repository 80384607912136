import React from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
//import ComingBack from "./pages/ComingBack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./App.css";
import AuthRoute from "./components/AuthRoute";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login/Login";
import Registration from "./pages/Registration";
import BinaryDetails from "./pages/Team/Tree";
import { FETCHED_USER, selectUser } from "./store/reducers/auth.reducers";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector((state) => selectUser(state));
  const dispatch = useDispatch();

  // useEffect(() => {
  //   localStorage.removeItem("freecoinToken");
  //   dispatch(REMOVED_USER());
  //   setIsLoading(false);
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("freecoinToken")) {
      const fetchUser = () => {
        const data = localStorage.getItem("freecoinToken");
        console.log(JSON.parse(data));
        dispatch(FETCHED_USER(JSON.parse(data)));
      };
      fetchUser();
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <p>Loading...</p>;

  return (
    <Router>
      <Switch>
        <Route
          path="/referral-link/:parentRefId/:type"
          component={Registration}
          exact
        />
        <Route path="/register-user" component={Registration} exact />
        <AuthRoute
          path="/tree-chart"
          Component={BinaryDetails}
          condition={!user}
          redirectTo="/login"
        />
        <AuthRoute
          path="/login"
          Component={Login}
          condition={user}
          redirectTo="/"
        />
        <AuthRoute
          path="/"
          Component={Dashboard}
          condition={!user}
          redirectTo="/login"
        />
        {/*
        //under construction
        <AuthRoute
          path="/"
          Component={ComingBack}
        
        /> */}
      </Switch>
    </Router>
  );
}



export default App;

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { constant } from "../../api/ApiConstant";
import { changePageName } from "../../store/actions/page.actions";
import axios from "../../api/axios";
import "./DirectReferrals.css";
import { selectUser } from "../../store/reducers/auth.reducers";
import BackButton from "../../components/BackButton";

const DirectReferrals = () => {
  const dispatch = useDispatch();

  const [referrals, setReferrals] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const user = useSelector((state) => selectUser(state));

  useEffect(() => {
    dispatch(changePageName("Direct Referrals"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getInvestments = async () => {
      try {
        const response = await axios.get(
          `${constant.team.directReferrals}/${user.id}`
        );
        console.log(response);
        setReferrals(response.data.userChilds);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    getInvestments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;

  return (
    <>
      <BackButton />
      <div style={{ justifyContent: "center" }}>
        <div style={{ overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                <th className="hidden">Serial</th>
                <th>User ID</th>
                <th>Name</th>
                {/* <th>Joined On</th> */}
                {/* <th>Mobile</th> */}
                <th>Phone Number</th>
                <th>Placement</th>
                <th>Team</th>
                {/* <th>Coin (FC)</th> */}
              </tr>
            </thead>
            <tbody>
              {referrals?.map((referral, index) => {
                if (referral.refId) {
                  return (
                    <tr>
                      <td className="hidden">{index + 1}</td>
                      <td>{referral.refId || "-"}</td>
                      <td>{referral.name || "-"}</td>
                      {/* <td>{referral.joiningDate || "-"}</td> */}
                      {/* <td>{referral.mobileNumber || "-"}</td> */}
                      <td>{referral.mobileNumber || "-"}</td>
                      <td>{referral.placementRefId || "-"}</td>
                      <td>{referral.side || "-"}</td>
                      {/* <td>{referral.amount || "-"}</td> */}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DirectReferrals;

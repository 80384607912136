import React from "react";
import { MdClear } from "react-icons/md";

import links from "../data/links";
import image from "../assets/logo.png";
import MenuLink from "./MenuLink";
import "./MenuDrawer.css";

const MenuDrawer = ({ onClose, visible, id }) => {
  if (!visible) return null;

  return (
    <>
      <div className="drawer-overlay" onClick={onClose}></div>
      <div className="drawer">
        <p className="close" onClick={onClose}>
          <MdClear size="24" />
        </p>
        <div className="drawer-top">
          <div className="user-details">
            <div className="logo__container">
              <img src={image} alt="avatar" className="logo__rounded" />
            </div>
          </div>
        </div>
        <div className="drawer-bottom">
          {links.map(({ Icon, title, subLinks, link, spacing }) => (
            <MenuLink
              key={title}
              Icon={Icon}
              title={title}
              subLinks={subLinks}
              link={link}
              spacing={spacing}
              onClose={onClose}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default MenuDrawer;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "../../api/ApiConstant";
import axios from "../../api/axios";
import "./Login.css";
import image from "../../assets/logo.png";
import { login } from "../../store/actions/auth.actions";
import Alerter from "../../components/Alerter";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdClear, MdContactSupport } from "react-icons/md";
import {
  removeFailure,
  removeSuccess,
  setFailure,
  setSuccess,
} from "../../store/actions/notify.actions";

const Login = () => {
  const [credentials, setCredentials] = useState({
    refId: "",
    password: "",
  });
  const [support, setSupport] = useState({
    refId: "",
    number: "",
    email: "",
    supportMessage: "",
  });

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [customerModel, setCustomerModel] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [refId,setRefId] = useState()

  
  const dispatch = useDispatch();
  const { success, failure } = useSelector((state) => state.notify);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleCustomerModal = () => setCustomerModel(!customerModel);
  const handleModalPassword = () => setModalPassword(!modalPassword);

  const handleLoading = (val) => setIsLoading(val);
  const handleVisible = () => setIsVisible(!isVisible);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(credentials, handleLoading));
  };

  const handleSupportChange = (e) => {
    const { name, value } = e.target;
    setSupport({ ...support, [name]: value });
    // alert(JSON.stringify(support));
  };

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      dispatch(removeFailure());
      setLoading(true);

      if (!support.supportMessage || !support.refId) {
        setLoading(false);
        return dispatch(setFailure("Please enter message/id"));
      }

      const response = await axios.post(constant.support.sendSupport, support);
      if (response.data) {
        dispatch(setSuccess("Support message sent"));
        setTimeout(() => dispatch(removeSuccess()), 2000);
      }
      setSupport("");
      setLoading(false);
      handleCustomerModal();
      handleModalPassword();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submitForgotPass = ()=>{
    axios.post(constant.auth.forgotPass,{refId:refId}).then((res)=>{
      if(res.status === 200){
        dispatch(setSuccess("Password sent to your email Id"))
        setModalPassword(false)
      }
    })
  }

  return (
    <div className="login">
      {/* <p style={{ fontSize: "1.5rem", color: "black", marginBottom: "1rem" }}>
        Security System Update
      </p> */}
      <div className="login-container">
        <div className="image-container">
          <img src={image} alt="logo" className="login-logo" />
        </div>
        <form onSubmit={handleSubmit} className="login__form">
          <h3 className="login-title">Login</h3>
          <div className="form-group-login">
            <label htmlFor="refId" className="form-label-login">
              User ID
            </label>
            <input
              type="text"
              placeholder="User ID"
              className="form-input-login"
              name="refId"
              value={credentials.refId}
              onChange={handleChange}
            />
          </div>
          <div className="form-group-login">
            <label htmlFor="password" className="form-label-login">
              Password
            </label>
            <div className="password__container">
              <input
                type={!isVisible ? "password" : "text"}
                placeholder="Password"
                className="form-password-input-login"
                name="password"
                value={credentials.password}
                onChange={handleChange}
              />
              {isVisible ? (
                <FaEye className="password__icon" onClick={handleVisible} />
              ) : (
                <FaEyeSlash
                  className="password__icon"
                  onClick={handleVisible}
                />
              )}
            </div>
            <p className="forgot__password" onClick={handleModalPassword}>Forgot Password</p>
          </div>
          <button
            type="submit"
            className="form-submitBtn-login"
            disabled={isLoading}
          >
            {isLoading ? "Loading" : "Login"}
          </button>
        </form>
      </div>
      <Alerter visible={failure} message={failure} type="error" />
      <Alerter visible={success} message={success} type="success" />
      <div className="support" onClick={handleCustomerModal}>
        <span className="support__iconText">Support</span>
        <MdContactSupport size="1em" />
      </div>
      {customerModel && (
        <>
          <div
            className="support__modal--overlay"
            onClick={handleCustomerModal}
          ></div>
          <div className="support__modal">
            <MdClear
              className="support__modal--clear"
              onClick={handleCustomerModal}
            />
            <h4 className="support__modalHeader">Customer Support</h4>
            <form className="support__form" onSubmit={handleSend}>
              <input
                className="support__modalInput"
                type="text"
                placeholder="Please Enter User Id"
                value={support.refId}
                name="refId"
                onChange={handleSupportChange}
              />
              <input
                className="support__modalInput"
                type="number"
                placeholder="Please enter Mobile Number"
                value={support.number}
                name="number"
                onChange={handleSupportChange}
              />
              <input
                className="support__modalInput"
                type="text"
                placeholder="Please Enter Email Id"
                value={support.email}
                name="email"
                onChange={handleSupportChange}
              />
              <textarea
                className="support__textarea"
                placeholder="Type here.."
                value={support.supportMessage}
                name="supportMessage"
                onChange={handleSupportChange}
              />
              <button
                type="submit"
                className="support__button dark__pink"
                disabled={loading}
              >
                {loading ? "Loading" : "Send"}
              </button>
            </form>
          </div>
          </>
        )}
          {modalPassword && (
            <>
            <div className="modal___overlay" onClick={handleModalPassword}>
            </div>
            <div className="modal___outer">
              <div className="modal___container">
                <div className="modal___input">
                  <label>Please Enter Your ID</label>
                  <input className="form___id" placeholder="ID" onChange={(ev)=>setRefId(ev.target.value)}/>
                </div>
              <button
              type="submit"
              className="Modal___submit" onClick={()=>submitForgotPass()}
              >Submit</button>
              </div>
            </div>
            </>
          )}
    </div>
    
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectUser } from "../store/reducers/auth.reducers";
import axios from "../api/axios";
import { constant } from "../api/ApiConstant";
import { changePageName } from "../store/actions/page.actions";

const StackingReport = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const [totalRow,setRow]=useState();

  const [reports, setReports] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("Stacking Report")), []);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${constant.wallet.stackingReport}/${user.id}`
        );
        setReports(
          response.data.data.map((rep) =>
            rep.type === "C"
              ? { ...rep, type: "Credited" }
              : rep.type === "D"
              ? { ...rep, type: "Debited" }
              : rep
          )
        );
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    })();
  }, [user.id]);
  useEffect(() => setRow(reports.length));


  console.log(reports);

  if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;

  return <div>Coming Soon</div>;
};

export default StackingReport;

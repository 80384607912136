import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import BackButton from "../components/BackButton";

import "./Support.css";
import { changePageName } from "../store/actions/page.actions";
import { useState } from "react";
import axios from "../api/axios";
import { constant } from "../api/ApiConstant";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/auth.reducers";
import { setSuccess, removeSuccess } from "../store/actions/notify.actions";

const Support = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));

  const [isLoading, setIsLoading] = useState(false);
  const [support, setSupport] = useState({
    supportMessage: "",
  });

  useEffect(() => {
    dispatch(changePageName("Support"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupport({ ...support, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(`/${constant.support.sendSupport}`, {
        supportMessage: support.supportMessage,
        refId: user.refId,
      });
      if (response.data) {
        dispatch(setSuccess("Support message sent"));
        setTimeout(() => dispatch(removeSuccess()), 2000);
        setSupport({ supportMessage: "" });
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <BackButton />
      <div className="card-container">
        <form className="support__form" onSubmit={handleSubmit}>
          <textarea
            className="support__textarea"
            placeholder="Type here.."
            value={support.supportMessage}
            name="supportMessage"
            onChange={handleChange}
          />
          <button
            type="submit"
            className="support__button dark__pink"
            disabled={isLoading}
          >
            {isLoading ? "Loading" : "Send"}
          </button>
        </form>
      </div>
    </>
  );
};

export default Support;

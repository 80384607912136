import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "../../api/ApiConstant";

import { changePageName } from "../../store/actions/page.actions";
import { selectUser } from "../../store/reducers/auth.reducers";
import axios from "../../api/axios";

const TransferWalletReport = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const [totalRow,setRow]=useState();

  const [reports, setReports] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("Transfer Wallet Report")), []);

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await axios.get(
          `${constant.TransferWalletReport}/${user?.id}`
        );
        console.log("Trasnferresssssssss",reports);
        setReports(
          response.data.data.map((rep) =>
            rep.type === "C"
              ? { ...rep, type: "Credited" }
              : rep.type === "D"
              ? { ...rep, type: "Debited" }
              : rep
          )
        );
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => setRow(reports.length));


  useEffect(() => {
    dispatch(changePageName("Transfer Wallet Report"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const count = 0

  if (initialLoading) {
    return <p style={{ color: "black" }}>Loading...</p>;
  }
  return (
    <div>
      <div style={{ justifyContent: "center" }}>
        <div style={{ overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                {/* <th style={{width: "15%"}}>Serial No</th> */}
                <th style={{width: "23%"}}>Date</th>
                <th style={{width : "23%"}}>Receiver's ID</th>
                <th style={{width: "30%"}}>Coin (FC)</th>
              </tr>
            </thead>
            <tbody>
              {reports?.map(({ respondedAt, toRefId, amount}, index) => (
                toRefId === "FM8627446" || toRefId === "FM0270" ? null : (
                  <tr>
                  {/* <td>{index-1}</td> */}
                  <td>{new Date(respondedAt).toDateString()}</td>
                  <td>{toRefId}</td>
                  <td>{Math.round(amount)}</td> 
                </tr> )
              )).reverse()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransferWalletReport;

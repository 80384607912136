import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";

import { changePageName } from "../store/actions/page.actions";
import "./SendReferralLink.css";
import { BiCopy } from "react-icons/bi";
import { selectUser } from "../store/reducers/auth.reducers";
import BackButton from "../components/BackButton";

const SendReferralLink = () => {
  const user = useSelector((state) => selectUser(state));

  const links = [
    {
      title: "Left Referral Link",
      link: `${window.location.origin}/referral-link/${user.refId}/left`,
    },
    {
      title: "Right Referral Link",
      link: `${window.location.origin}/referral-link/${user.refId}/right`,
    },
  ];
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePageName("Invitation Link"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackButton />
      <div className="card-container sendReferral-container">
        <h4 className="page-title">Send Referral Link</h4>
        <div className="referral-links">
          {links.map(({ link, title }) => (
            <CopyToClipboard text={link} onCopy={onCopyText} key={link}>
              <p className="send-link">
                <span>{title}</span>
                <BiCopy />
              </p>
            </CopyToClipboard>
          ))}
        </div>
        {isCopied && <p className="alert dark__pink">Copied</p>}
      </div>
    </>
  );
};

export default SendReferralLink;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./Registration.css";
import axios from "../api/axios";
import image from "../assets/logo.png";
import { constant } from "../api/ApiConstant";
import { selectUser } from "../store/reducers/auth.reducers";
import { setFailure } from "../store/actions/notify.actions";
import { useDispatch } from "react-redux";

const Registration = () => {
  const { type, parentRefId } = useParams();

  const [currentType, setCurrentType] = useState(type);
  const [refName, setRefName] = useState("");
  const [placementRefId, setPlacementRefId] = useState("");
  const [placementRefName, setPlacementRefName] = useState("");

  const user = useSelector((state) => selectUser(state));

  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState({
    parentRefId: parentRefId ? parentRefId : "",
    name: "",
    gender: "M",
    mailId: "",
    mobileNumber: "",
    isLeft: currentType === "left",
    isRight: currentType === "right",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  useEffect(() => {
    if (parentRefId) {
      handleCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheck = async () => {
    const res = await axios.get(`${constant.refUser}/${newUser.parentRefId}`);
    setRefName(res.data.data.name);
  };

  useEffect(() => {
    setNewUser({
      ...newUser,
      isLeft: currentType === "left",
      isRight: currentType === "right",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    // setShowSuccess(true);
    setIsLoading(true);
    try {
        const response6 = await axios.post(constant.previousBalance)
        setIsLoading(false);
        // window.location.reload();
        setShowSuccess(true)
    }
    catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (currentType === "") return;

      const response = await axios.post(constant.registration.create, newUser);

      if (response.data.data.isUserCreated) {
        console.log(response);
        setShowConfirm(true);
        setPlacementRefId(response.data.data.placementRefId);
        setPlacementRefName(response.data.data.placementName);

        // await axios.get(
        //   `${constant.registration.placementDetails}/${response.data.data.userId}`
        // );
      } else {
        dispatch(setFailure(response.data.data.message));
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const returnPage = (e) => {
    e.preventDefault()
    window.location.reload();
  }

  if (showSuccess) {
    return (
      <div className="success__messageContainer">
        <p className="success__message">
          <span>
            New User Created Successfully !!!! Please check your email for user
            id and password
          </span>
          <span className="second__line">
            Want to create another user
            {/* <Link to={`/register-user/${user?.refId}`} className="back__link"> */}
            <Link className="back__link" onClick={returnPage}>
              here
            </Link>
          </span>
          <span className="second__line">
            Want to go back
            <Link to="/" className="back__link">
              here
            </Link>
          </span>
          <span className="second__line"></span>
        </p>
      </div>
    );
  }

  if (showConfirm) {
    return (
      <div className="center__container1">
        <div className="confirm__container">
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Referral ID</label>
            <p className="confirm__formContent">{newUser.parentRefId || "-"}</p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Referral Username</label>
            <p className="confirm__formContent">{refName || "-"}</p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Team</label>
            <p className="confirm__formContent">{currentType || "-"}</p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Your Username</label>
            <p className="confirm__formContent">{newUser.name || "-"}</p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Email Address</label>
            <p className="confirm__formContent">{newUser.mailId || "-"}</p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Mobile Number</label>
            <p className="confirm__formContent">
              {newUser.mobileNumber || "-"}
            </p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Gender</label>
            <p className="confirm__formContent">{newUser.gender || "-"}</p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Placement ID</label>
            <p className="confirm__formContent">{placementRefId || "-"}</p>
          </div>
          <div className="confirm__formGroup">
            <label className="confirm__formLabel">Placement Username</label>
            <p className="confirm__formContent">{placementRefName || "-"}</p>
          </div>
          <div className="button__container">
            <button
              type="submit"
              className="submit__btn dark__blue spacing-left"
              onClick={handleConfirm}
            >
                {isLoading ? "Loading" : "Done"}
              {/* Done */}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dark__bgBlue">
      <div className="center-container ">
        <div className="card-container referral-container">
          <div className="logo-center">
            <div className="logo-container logo-middle">
              <img src={image} alt="logo" className="logo" />
            </div>
          </div>
          <h4 className="referral-page-title">Registration</h4>
          <form className="form-referral" onSubmit={handleSubmit}>
            <div className="group-referral">
              <div className="form_group-referral">
                <label className="form_label-referral">Referral ID</label>
                <input
                  type="text"
                  className="form_input-referral"
                  value={newUser.parentRefId}
                  onChange={handleChange}
                  name="parentRefId"
                  placeholder="Enter Parent Ref ID"
                />
              </div>
              {!parentRefId && (
                <button className="check-btn" onClick={handleCheck}>
                  Check
                </button>
              )}
              {type && (
                <div className="form_group-referral1">
                  <label className="form_label-referral1">Team</label>
                  <p className="form_input-referral1">{currentType}</p>
                </div>
              )}
            </div>
            <div className="form_group-referral">
              {!type && (
                <div className="form_group-referral">
                  <label className="form_label-referral">Team</label>
                  <select
                    value={currentType}
                    onChange={(e) => setCurrentType(e.target.value)}
                    className="form_select-referral"
                    required
                  >
                    <option value="" disabled selected>
                      -- select an option --
                    </option>
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </select>
                </div>
              )}
            </div>
            <div className="form_group-referral">
              <label className="form_label-referral">Referral Username</label>
              <input
                type="text"
                className="form_input-referral"
                value={refName}
                onChange={handleChange}
                name="refName"
                placeholder="Referral Username"
                required
                disabled
              />
            </div>
            <div className="form_group-referral">
              <label className="form_label-referral">Your Username</label>
              <input
                type="text"
                className="form_input-referral"
                value={newUser.name}
                onChange={handleChange}
                name="name"
                placeholder="Username as in Bank Passbook"
                required
              />
            </div>
            <div className="form_group-referral">
              <label className="form_label-referral">Email Address</label>
              <input
                type="email"
                className="form_input-referral"
                value={newUser.mailId}
                onChange={handleChange}
                name="mailId"
                placeholder="Enter Email Address"
                required
              />
            </div>
            <div className="form_group-referral">
              <label className="form_label-referral">Mobile Number</label>
              <PhoneInput
                type="text"
                country={"in"}
                enableAreaCodes={true}
                value={newUser.mobileNumber}
                onChange={(value) =>
                  setNewUser({ ...newUser, mobileNumber: value })
                }
                containerStyle={{
                  width: "100%",
                  // background: "#191e31",
                  background: "#d3d3d3",
                }}
                inputStyle={{
                  // background: "#191e31",
                  background: "#d3d3d3",
                  // color: "white",
                  color: "#000",
                  border: "none",
                }}
              />
            </div>
            <div className="form_group-referral">
              <label className="form_label-referral">Gender</label>
              <select
                className="form_select-referral"
                name="gender"
                onChange={handleChange}
                value={newUser.gender}
                required
              >
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </div>
            <div className="btn_container btn-spacing">
              <button type="submit" className="submit__btn dark__pink">
                {isLoading ? "Loading" : "Create"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Registration;

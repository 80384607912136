import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "../../api/ApiConstant";

import { changePageName } from "../../store/actions/page.actions";
import { selectUser } from "../../store/reducers/auth.reducers";
import axios from "../../api/axios";

const EarningsWalletReport = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const [totalRow,setRow]=useState();

  const [reports, setReports] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(changePageName("Earning Wallet Report")), []);

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await axios.get(
          `${constant.wallet.earningWalletReport}/${user.id}`
        );
        setReports(
          response.data.data.map((rep) =>
            rep.type === "C"
              ? { ...rep, type: "Credited" }
              : rep.type === "D"
              ? { ...rep, type: "Debited" }
              : rep
          ) 
        );
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    console.log(reports);

    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => setRow(reports.length));


  if (initialLoading) {
    return <p style={{ color: "black" }}>Loading...</p>;
  }

  return (
    <div>
      <div style={{ justifyContent: "center" }}>
        <div style={{ overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                <th>Serial No</th>
                <th>TopUp Date</th>
                <th>Coin (FC)</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {reports?.map(({ transactionAt, fcAmount, message }, index) => (
                <tr>
                  <td>{totalRow-index}</td>
                  <td>{new Date(transactionAt).toDateString()}</td>
                  <td>{fcAmount}</td>
                  <td>{`${message.split("/")[2]}  ${message
                    .split("/")[1]
                    .split("_")
                    .splice(1)
                    .join(" ")}`}</td>
                </tr>
              )).reverse()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EarningsWalletReport;

import pdf from '../assets/freeminers.pdf';

import React from "react";

const Plan = () => {
  return (
    <div>
      <object width="100%" height="500" data={pdf} type="application/pdf">   </object>
      </div>
  );
};

export default Plan;